<template>
    <main>
        <v-container v-if = "room">
            <v-layout row>
                <v-flex xs12 md4 class = "pa-6">
                    <h4>Room</h4>
                    <v-card class = "mt-2 mb-2" :class = "{
                    [room.status]: true
                }" >                    
                    <cover
                        :cover="room.cover"
                        class="vjs-custom-skin"
                        id="video_preview">
                        </cover>
                    <v-card-title>
                        {{ room.name }}
                    </v-card-title>
                    <v-card-subtitle>
                        ID: {{ room.id }}
                    </v-card-subtitle>
                    <v-list>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-avatar
                                    :color="statusColor(room.status)"
                                    size="10"
                                    ></v-avatar>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.status }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-account-star-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.presenter ? 1 : 0 }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Presenters
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-account-group-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.viewers || 0 }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Viewers
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-alpha-s-circle-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ room.sessions.length }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Sessions
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-timer-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ totalStreamTime(room) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Total time streamed
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-calendar-check-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ formatDate(room.createdAt) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Created At
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>
                                    mdi-calendar-remove-outline
                                </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>
                                    {{ formatDate(room.validUntil) }}
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Valid until
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
                </v-flex>
                <v-flex xs12 md4 class = "pa-6 scrollable">
                    <h4>Timeline</h4>
                    <v-layout column v-for = "(event, index) in events" :key = '`event${index}`' class = "pt-2 pb-2">
                        <small style = "font-weight: 300">
                            {{formatDate(event.createdAt, true)}}
                        </small>
                        <v-card>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-avatar>
                                        <v-avatar>
                                            <img
                                                :src="event.user.image"
                                            >
                                        </v-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{event.user.username}}, <small>ID: {{event.user.id}}</small>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            {{ formatAction(event) }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-layout>
                </v-flex>
                <v-flex xs12 md4 class = "pa-6 scrollable">
                    <h4>Presenters</h4>
                    <v-card class = "mt-2 mb-2 pa-2">
                        <v-layout column>
                        <v-text-field v-model = "search.presenter"  
                            filled
                            rounded
                            hide-details
                            dense placeholder = "Search" 
                            prepend-inner-icon="mdi-magnify"/>
                         <v-layout wrap v-if = "filteredPresenters.length" align-center class = "mt-2 mb-2">
                            <div v-for = "(item, index) in filteredPresenters" :key = "`presenter${index}`">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-avatar v-on = "on" v-bind="attrs">
                                        <img
                                            :src="item.user.image"
                                        >
                                    </v-avatar>
                                    </template>
                                    <span>
                                        {{ item.user.username }}
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-layout>
                        <!--
                        <v-list v-if = "filteredPresenters.length">
                            <v-list-item v-for = "(item, index) in filteredPresenters" :key = "`presenter${index}`">
                                <v-list-item-avatar>
                                    <v-avatar>
                                        <img
                                            :src="item.user.image"
                                        >
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{item.user.username}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ item.endpoint ? 'Presenting' : 'Not presenting' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Connection State: {{ item.endpointState.connectionState === 'CONNECTED' ? 'CONNECTED' : 'DISCONNECTED' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Media State: {{ item.endpointState.mediaState === 'CONNECTED' ? 'CONNECTED' : 'DISCONNECTED' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Audio State: {{ hasMedia(item.endpointState, 'audio', 'out') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Video State: {{ hasMedia(item.endpointState, 'video', 'out') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        -->
                        <v-card-text v-else>
                            No presenters
                        </v-card-text>
                        </v-layout>
                    </v-card>
                    <h4>Viewers</h4>
                    <v-card class = "mt-2 mb-2 pa-2">
                        <v-layout column>
                        <v-text-field v-model = "search.viewer" filled
                            rounded
                            dense placeholder = "Search" 
                            hide-details
                            prepend-inner-icon="mdi-magnify" />
                        <v-layout wrap v-if = "filteredViewers.length" align-center class = "mt-2 mb-2">
                            <div v-for = "(item, index) in filteredViewers" :key = "`viewers${index}`" class ="avatar_wrapper" @click = "viewerDetail(item)">
                                <v-tooltip bottom>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-avatar v-on = "on" v-bind="attrs">
                                        <img
                                            :src="item.user.image"
                                        >
                                    </v-avatar>
                                </template>
                                    <span>
                                        {{ item.user.username }}
                                    </span>
                                </v-tooltip>
                            </div>
                        </v-layout>
                        
                        <!--<v-list v-if = "viewers.length">
                            <v-list-item v-for = "(item, index) in viewers" :key = "`viewers${index}`">
                                <v-list-item-avatar>
                                    <v-avatar>
                                        <img
                                            :src="item.user.image"
                                        >
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{item.user.username}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ item.endpoint ? 'Viewing' : 'Not viewing' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Connection State: {{ item.endpointState.connectionState === 'CONNECTED' ? 'CONNECTED' : 'DISCONNECTED' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Media State: {{ item.endpointState.mediaState === 'CONNECTED' ? 'CONNECTED' : 'DISCONNECTED' }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Audio State: {{ hasMedia(item.endpointState, 'audio', 'in') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Video State: {{ hasMedia(item.endpointState, 'video', 'in') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>-->
                        <v-card-text v-else>
                            No viewers
                        </v-card-text>
                        </v-layout>
                    </v-card>
                    <h4>Actions</h4>
                    <v-layout wrap>
                        <v-btn @click="deleteRoom">Delete</v-btn>
                        <div style = "width:10px"/>
                        <v-btn @click="clearChat">Clear chat</v-btn>
                    </v-layout>
                </v-flex>
            </v-layout>
        </v-container>
        <v-container v-else>
            <v-layout align-center justify-center>
                <v-progress-circular indeterminate/>
            </v-layout>
        </v-container>
        <v-dialog v-model = "presenterDialog.shown" :max-width = "500">
            <v-card>
                <v-card-text v-if = "presenterDialog.user.user">
                    <v-layout column justify-center class = "pt-4 pb-4">
                        <v-layout wrap align-center>
                            <v-avatar><img :src = "presenterDialog.user.user.image"></v-avatar><h3 class = "pl-4">{{presenterDialog.user.user.username}}</h3>
                        </v-layout>
                        <v-layout wrap>
                            <v-btn @click="kick(presenterDialog.user)">Kick</v-btn>
                        </v-layout>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model = "viewerDialog.shown" :max-width = "500">
            <v-card>
                <v-card-text v-if = "viewerDialog.user.user">
                    <v-layout column justify-center class = "pt-4 pb-4">
                        <v-layout wrap align-center>
                            <v-avatar><img :src = "viewerDialog.user.user.image"></v-avatar><h3 class = "pl-4">{{viewerDialog.user.user.username}}</h3>
                        </v-layout>
                        <v-layout wrap>
                            <v-btn @click="kick(viewerDialog.user)">Kick</v-btn>
                        </v-layout>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog v-model = "areYouSureDialog.shown" :max-width = "500">
            <v-card>
                <v-card-title>
                    Are you sure?
                </v-card-title>
                <v-card-text>
                {{areYouSureDialog.text}}
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="areYouSureDialog.shown = false" plain>No</v-btn>
                <v-btn @click="() => {
                    areYouSureDialog.action()
                    areYouSureDialog.shown = false
                }" color="error">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </main>
</template>

<script>
    import axios from 'axios'
    import { URL } from '../../utils/consts'
    import { getUser } from "../../utils/login";
    import Cover from '../../components/Cover';
    import moment from 'moment';
    export default {
        components: {
            'cover': Cover
        },
        data() {
            return {
                room: null,
                events: null,
                presenters: null,
                viewers: null,
                search: {
                    presenter: '',
                    viewer: ''
                },
                presenterDialog: {
                    shown: false,
                    user: {}
                },
                viewerDialog: {
                    shown: false,
                    user: {}
                },
                areYouSureDialog: {
                    shown: false,
                    action: {},
                    text: ''
                }
            }
        },
        computed: {
            filteredPresenters() {
                if (!this.search.presenter) return this.presenters
                return this.presenters.filter(presenter => {
                    return presenter.user.username.toLowerCase().includes(this.search.presenter.trim().toLowerCase())
                });
            },
            filteredViewers() {
                if (!this.search.viewer) return this.viewers
                return this.viewers.filter(viewer => {
                    return viewer.user.username.toLowerCase().includes(this.search.viewer.trim().toLowerCase())
                });
            }
        },
        methods: {
            viewerDetail(user) {
                this.viewerDialog.shown = true;
                this.viewerDialog.user = user;
            },
            presenterDetail(user) {
                this.presenterDialog.shown = true;
                this.presenterDialog.user = user;
            },
            hasMedia(state, mediaType, inputType) {
                if (!state[mediaType]) return false;
                if (!state[mediaType][inputType]) return false;
                return state[mediaType][inputType]
            },
            statusColor(status) {
                if (status === 'ACTIVE') return '#0F0';
                if (status === 'WAITING') return '#00F';
                return '#F00';
            },
            formatAction(event) {
                switch(event.type) {
                    case 'JOIN': return 'Joined'
                    case 'LEAVE': return 'Left'
                    case 'RESUME': return 'Resumed streaming'
                    case 'PAUSE': return 'Paused streaming'
                    case 'TIP': return 'Sent a tip'
                    case 'MESSAGE': return 'Sent a message'
                }
            },
            totalStreamTime(room) {
                const seconds = [0, ...room.sessions.map(s => {
                    console.log(s);
                    if (s.duration == null) {
                        return moment(moment.utc()).diff(s.start, 'seconds')
                    }
                    return parseInt(s.duration)
                })].reduce((all, session) => (all + session));
                console.log(seconds);
                const second = Math.floor(seconds % 60);
                const minutes = Math.floor((seconds / 60) % 60);
                const hours = Math.floor((seconds / 60 / 60) % 60);
                const days = Math.floor((seconds / 60 / 60 / 24) % 24);
                return [
                    ...[{
                        value: days,
                        label: 'd'
                    }, {
                        value: hours,
                        label: 'h'
                    }, {
                        value: minutes,
                        label: 'm'
                    }].filter(s => s.value)
                    ,
                    {
                        value: second,
                        label: 's'
                    }
                ]
                .map(s => `${s.value} ${s.label}`)
                .join(' ');
            },
            async fetch() {
                const { data } = await axios.get(URL() + "rooms/admin/"+this.$route.params.id, {
                    headers: {
                    Authorization: "Bearer " + getUser().token,
                    }
                })
                this.room = data.room;
                this.events = data.events.filter(e => e.user);
                this.presenters = data.presenters;
                this.viewers = data.viewers;
                setTimeout(() => {
                    this.fetch()
                }, 1000);
            },
            async kick(user) {
                
                this.showAreYouSureDialog(
                    'You want to clear chat in this room?',
                    async () => {
                    await axios.delete(URL() + `rooms/admin/${this.$route.params.id}/kick/${user.socket.connectionId}`, {
                        headers: {
                        Authorization: "Bearer " + getUser().token,
                        }
                    })
                })
            },
            async clearChat() {
                this.showAreYouSureDialog(
                    'You want to clear chat in this room?',
                    async () => {
                        await axios.delete(URL() + `rooms/${this.$route.params.id}/chat`, {
                            headers: {
                            Authorization: "Bearer " + getUser().token,
                            }
                        })
                })
            },
            deleteRoom() {
                this.showAreYouSureDialog(
                    'You want to delete this room?',
                    async () => {
                        await axios.delete(URL() + `rooms/${this.$route.params.id}`, {
                                            headers: {
                                            Authorization: "Bearer " + getUser().token,
                                            }
                                        })
                        this.$router.go(-1)
                    }
                )
            },
            async showAreYouSureDialog(text, action) {
                this.areYouSureDialog.action = action;
                this.areYouSureDialog.text = text;
                this.areYouSureDialog.shown = true;
            },
            formatDate(date, seconds = false) {
                return moment(date).format('DD. MM. YYYY, HH:mm' + (seconds ? ':ss' : ''))
            }
        },
        created() {
            this.fetch()
        }
    }
</script>

<style lang="scss" scoped>
    h4 {
        font-weight: 300
    }
    .ACTIVE { 
        border-left: 2px solid #0F0;
    }
    .WAITING { 
        border-left: 2px solid #00F;
    }
    .IDLE {
        border-left: 2px solid #F00;
    }
    
    .status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
    }
    
    #video_preview {
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: center;
    }
    .scrollable {
        max-height: 100vh;
        overflow: auto;
    }
    .avatar_wrapper {
        max-width: 24px;
        transition: all .3s;
        transform: scale(1);
        margin:0;
        cursor: pointer;
        &:hover {
            max-width: 48px;
        }
    }
</style>