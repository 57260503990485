/* eslint-disable no-unused-vars */

import { URL } from "./consts";
import { getUser } from './login'
import axios from 'axios';

const get = async (endpoint) => 
    await axios.get(URL() + endpoint, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })


const post = async (endpoint, data) => 
await axios.post(URL() +  endpoint, data, {
    headers: {
    Authorization: "Bearer " + getUser().token,
    }
})

const del = async (endpoint) => 
await axios.delete(URL() +  endpoint, {
    headers: {
    Authorization: "Bearer " + getUser().token,
    }
})

const put = async (endpoint, data) => {
    console.log(data);
return await axios.put(URL() +  endpoint, data, {
    headers: {
        Authorization: "Bearer " + getUser().token,
    }
})
}


export const createChallenge = async (params) => post(`rooms/${params.roomId}/challenge`, {
    name: params.name,
    goalPledge: params.goalPledge,
    minPledge: params.minPledge,
    startsAt: params.startsAt,
    endsAt: params.endsAt,
    entryFee: params.entryFee,
})

export const clapChallenge = async (params) => post(`rooms/challenge/${params.challengeId}/clap`, {
    amount: params.amount,
})

export const cancelChallenge = async (params) => post(`rooms/challenge/${params.challengeId}/cancel`)

export const createSuggestedChallenge = async (params) => post(`rooms/challenge/admin/suggested`, params)

export const getSuggestedChallenges = async () => get(`rooms/challenge/admin/suggested`)

export const removeSuggestedChallenge = async (suggestedChallengeId) => del(`rooms/challenge/admin/suggested/${suggestedChallengeId}`)

export const updateSuggestedChallenge = async (suggestedChallengeId, params) => put(`rooms/challenge/admin/suggested/${suggestedChallengeId}`, params)