import kurentoUtils from 'kurento-utils'
import { WebSocketURL } from '../utils/consts'
import { getUser } from '../utils/login'
import { viewer } from './viewerRtc'

export var ws;
export var webRtcPeer;

export var viewerRtcPeers = {};

let video;

export function videoFeed(videowrapper) {
	video = videowrapper
	console.log('video', videowrapper);
}

export function onBeforeUnload() {
    ws.close()
}

export function chatMessage(message) {
	console.log('Sending chat message', message);
	sendMessage({
		type: 'message',
		message
	})
}

let callback = null
let remoteCandidateQueue = []
window.remoteCandidateQueue = remoteCandidateQueue

export function setSocket(connectionId) {
	ws = new WebSocket(WebSocketURL());
	ws.onopen = function() {
		const user = getUser()
		authenticate(user.token, connectionId)
		messageQueue.forEach(message => {
			ws.send(message)
		})
		messageQueue.splice(0)

		setInterval(() => {
			sendMessage({type: 'ping'})
		}, 30 * 1000)
	}
	ws.onmessage = function(message) {
		var parsedMessage = JSON.parse(message.data);
		console.info('Received message: ' + parsedMessage.type, webRtcPeer?.peerConnection?.signalingState);
	
		if (callback)
			callback(parsedMessage)
	
		switch (parsedMessage.type) {
		case 'resume':
			resumeResponse(parsedMessage);
			break;
		case 'watch':
			watchResponse(parsedMessage);
			break;
		case 'pause':
			break;
		case 'onIceCandidate':
			if (!webRtcPeer) {
				console.error('No Web RTC PEER!');
			}
			if (parsedMessage.connectionId) {
				viewerRtcPeers[parsedMessage.connectionId].onIceCandidate(parsedMessage.candidate);
			} else {
				if (webRtcPeer.peerConnection.signalingState == 'stable')
					webRtcPeer.addIceCandidate(parsedMessage.candidate)
				else {
					remoteCandidateQueue.push(parsedMessage.candidate)
				}
			}
			break;
		default:
			console.error('Unrecognized message', parsedMessage);
		}
	}
	ws.onclose = function() {
		ws = null
		setSocket()
	}
}

export function setupCallback(cb) {
	callback = cb;
}

function onError(err) {
    console.error(err)
}

function resumeResponse(message) {
	webRtcPeer.processAnswer(message.sdpAnswer, (err) => {
		if (err)
			return console.log('Process Answers error', err)
		remoteCandidateQueue.forEach(candidate => {
			webRtcPeer.addIceCandidate(candidate);
		})
		remoteCandidateQueue = []
		localCandidateQueue.forEach(m => sendMessage(m))
		localCandidateQueue = []
	});
}

function watchResponse(message) {
	const { connectionId } = message;
	const viewerPeer = viewerRtcPeers[connectionId]
	console.log(message);
	if (viewerPeer) {
		viewerPeer.processAnswer(message.sdpAnswer)
	}
}

export function authenticate(id, connectionId) {
	sendMessage({
		type : 'subscribe',
		token: id,
		connectionId
	});
	setInterval(() => {
		sendMessage({
			type : 'ping',
			connectionId
		});
	}, 30 * 1000)
}

export function toggleCamera(enabled) {
	sendMessage({
		data: {
			enabled
		},
		notificationType:"presenterVideo",
		type:"notification"
	})
}

export function creating() {
	console.log(webRtcPeer);
	if (!webRtcPeer) {
		// showSpinner(video);

		console.log('LocalVideo', video)
		var options = {
			localVideo: video,
			onicecandidate : onIceCandidate,
			onaddstream: (x) => {
				console.warn('Added Stream', x)
			},
			configuration: {
				iceServers: [
					{
						urls: "stun:eu-turn1.xirsys.com"
					},
					{
						urls: [
							"turn:eu-turn1.xirsys.com:80?transport=udp",
							"turn:eu-turn1.xirsys.com:3478?transport=udp",
							"turn:eu-turn1.xirsys.com:80?transport=tcp",
							"turn:eu-turn1.xirsys.com:3478?transport=tcp",
							"turns:eu-turn1.xirsys.com:443?transport=tcp",
							"turns:eu-turn1.xirsys.com:5349?transport=tcp"
                        ], 
                        username: "MdiqSAFSyC7mG1-Rh4ZG7faOb15ov0rHpxFyeBaVl5HThU00PONEo6-X_2K_sbn-AAAAAF7XPWNlem1pZGFwcHM=",
						credential: "10022aae-a560-11ea-9f84-0242ac140004"
					},
				]
            }
        }

		webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function(error) {
			if(error) return onError(error);

			this.generateOffer(() => {});
		});

	}
}

export function presenter(room) {
	console.log(webRtcPeer);
	if (!webRtcPeer) {
		// showSpinner(video);

		console.log('LocalVideo', video)
		var options = {
			localVideo: video,
			onicecandidate : onIceCandidate,
			onaddstream: (x) => {
				console.warn('Added Stream', x)
			},
			configuration: {
				iceServers: [
					{urls: "stun:eu-turn7.xirsys.com"},
					{urls: [
                        "turn:eu-turn7.xirsys.com:80?transport=udp",
                        "turn:eu-turn7.xirsys.com:3478?transport=udp",
                        "turn:eu-turn7.xirsys.com:80?transport=tcp",
                        "turn:eu-turn7.xirsys.com:3478?transport=tcp",
                        "turns:eu-turn7.xirsys.com:443?transport=tcp",
                        "turns:eu-turn7.xirsys.com:5349?transport=tcp",
                        ], 
                        username: "ZRkFgKy84trjeBCuNBvdVy0_MaPpJK-Iq7t-cTiWDnTKS_6l0a4QZCm9YlEdmk_QAAAAAF9GHBxrb25la3RycnRj",
                        credential: "7bf17ba0-e775-11ea-8c28-0242ac140004"},
				]
            }
        }

		webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerSendonly(options, function(error) {
			if(error) return onError(error);

			this.generateOffer((err, sdp) => onOfferPresenter(err, sdp, room));
		});

	}
}

export function getMediaStream() {
	return webRtcPeer?.getRemoteStream()
} 

export function refreshVideos() {
	video?.play()
	Object.values(viewerRtcPeers).forEach(peer => {
		peer?.refreshVideo()
	})
}

function onOfferPresenter(error, offerSdp, room) {
    if (error) return onError(error);

	var message = {
		type : 'resume',
        sdpOffer : offerSdp,
		room
	};
	sendMessage(message);
}

export function join(streamId, joinType, ref = null) {
	sendMessage({
		type: 'join',
		room: streamId,
		joinType,
		ref
	})
}

export function userStates(userIds) {
	sendMessage({
		type: 'userStates',
		userIds
	})
}

export function setupViewer(roomId, connectionId, video, callback) {
	viewerRtcPeers[connectionId] = viewer({
		roomId,
		connectionId,
		video,
		callback,
		socket: ws,
	});
}

export function v(streamId, video) {

		var options = {
			remoteVideo: video,
			onicecandidate : onIceCandidate,
			
			onaddstream: (x) => {
				callback({
					type: 'updateVideo',
					stream: x.stream
				})
			},
			configuration: {
				iceServers: [
					{urls: "stun:eu-turn7.xirsys.com"},
					{urls: [
"turn:eu-turn7.xirsys.com:80?transport=udp",
"turn:eu-turn7.xirsys.com:3478?transport=udp",
"turn:eu-turn7.xirsys.com:80?transport=tcp",
"turn:eu-turn7.xirsys.com:3478?transport=tcp",
"turns:eu-turn7.xirsys.com:443?transport=tcp",
"turns:eu-turn7.xirsys.com:5349?transport=tcp",
], 
username: "ZRkFgKy84trjeBCuNBvdVy0_MaPpJK-Iq7t-cTiWDnTKS_6l0a4QZCm9YlEdmk_QAAAAAF9GHBxrb25la3RycnRj",
credential: "7bf17ba0-e775-11ea-8c28-0242ac140004"},
				]
			}
		}

		webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function(error) {
			if(error) return onError(error);

			this.generateOffer((error, offerSdp) => onOfferViewer(error, offerSdp, streamId));
		});
}

function onOfferViewer(error, offerSdp, room) {
	if (error) return onError(error)

	var message = {
		type : 'watch',
		sdpOffer : offerSdp,
		room
	}
	sendMessage(message);
}
let localCandidateQueue = []
window.localCandidateQueue = localCandidateQueue
function onIceCandidate(candidate) {
	   console.log('Local candidate' + JSON.stringify(candidate));
	   console.log(webRtcPeer.peerConnection.signalingState)

	   var message = {
	      type : 'onIceCandidate',
	      candidate : candidate
	   } 
	   if (webRtcPeer.peerConnection.signalingState == 'stable')
	   	sendMessage(message)
	   else
	   	localCandidateQueue.push(message)
}

export function leave() {
		var message = {
				type : 'leave'
		}
		sendMessage(message);
		dispose();
		console.log('Sending leave message');
		ws.close();
		ws = null
}

export function stop() {
		var message = {
				type : 'pause',
				reason: 'Stopped the stream'
		}
		sendMessage(message);
		dispose();
}

export function startRecording() {
    if (webRtcPeer) {
		var message = {
            id : 'startRecord'
        }
        sendMessage(message);
    }
}

export function stopRecording() {
    if (webRtcPeer) {
		var message = {
            id : 'stopRecord'
        }
        sendMessage(message);
    }
}

function dispose() {
	if (webRtcPeer) {
		webRtcPeer.dispose();
		webRtcPeer = null;
	}
}

const messageQueue = [];

export function sendMessage(message) {
	console.log(ws);
	var jsonMessage = JSON.stringify(message);
	console.log('Sending message: ' + message.type, webRtcPeer?.peerConnection?.signalingState);
	if (ws == null || ws.readyState != 1) {
		messageQueue.push(jsonMessage)
	} else {
		ws.send(jsonMessage);
	}
}
