<template>
    <v-layout column>
        <v-layout wrap><h3>{{ data.name }}</h3><v-spacer/><h4>{{ data.claps }} / {{ data.goalClaps }}</h4></v-layout>
        <div class = "bar_wrapper" v-if = "data.status === 'ACTIVE'">
            <div class = "bar" :style = "{ width: percentage + '%' }">
            </div>
        </div>
        <v-layout wrap align-center pt-4>
            <v-flex>
                <h5>{{ timeLeft }}</h5>
            </v-flex>
            <v-spacer/>
            <v-flex shrink
                    v-if = "type === 'PRESENTER' && data.status === 'ACTIVE'">
                <v-btn class = "ml-2" @click = "cancelChallenge" 
                                outlined
                                raised
                                rounded>
                    Cancel
                </v-btn>
            </v-flex>
            <v-flex shrink
                    v-else-if = "type === 'VIEWER' && data.status === 'ACTIVE'">
                <v-btn @click = "clapChallenge" 
                                outlined
                                raised
                                rounded>
                    Clap
                </v-btn>
            </v-flex>
        </v-layout>
        <v-dialog v-model = "pledgeDialog.dialog" max-width="500">
            <v-card>
                <v-card-title>Pledge</v-card-title>
                <v-card-text>
                    <v-layout wrap>
                        <v-flex shrink v-for = "(item, index) in pledgeValues" :key = "`pledge${index}`" class = "pa-1">
                            <v-btn
                                elevation="2"
                                outlined
                                raised
                                rounded
                                @click="pledge(item)"
                                >{{item}}cr</v-btn>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
    import moment from 'moment'
    import {
        clapChallenge,
        cancelChallenge,
    } from '../utils/api'
    export default {
        props: ['data', 'stream', 'type'],
        data() {
            return {
                timeLeft: "",
                interval: null,
                pledgeDialog: {
                    dialog: false,
                },
                secondsLeft: 0,
                pledgeValues: [
                    10,
                    50,
                    100,
                    500,
                    1000,
                ]
            }
        },
        created () {
            this.interval = setInterval(() => {
                let secondsLeft = 0;
                if (this.data.status === 'ACTIVE') {
                    secondsLeft = Math.abs(moment().utc().diff(moment(this.data.nextRecalculationAt), 'seconds'));
                }
                const seconds = Math.floor(secondsLeft % 60);
                const minutes = Math.floor(secondsLeft / 60 % 60);
                const hours = Math.floor(secondsLeft / 60 / 60 % 24);
                this.secondsLeft = secondsLeft;
                this.timeLeft = this.zeroFormatted(hours) + ':' + this.zeroFormatted(minutes) + ':' + this.zeroFormatted(seconds)
            }, 1000);
        },
        beforeDestroy () {
            if (this.interval)
                clearInterval(this.interval)
        },
        methods: {
            zeroFormatted(value) {
                if (value < 10) return `0${value}`
                return `${value}`
            },
            async clapChallenge() {
                await clapChallenge({
                    challengeId: this.data.id,
                    amount: 10
                })
            },
            async cancelChallenge() {
                await cancelChallenge({
                    challengeId: this.data.id
                })
            },
        },
        computed: {
            percentage() {
                const lastRecalculationAt = new Date(this.data.lastRecalculationAt).getTime();
                const nextRecalculationAt = new Date(this.data.nextRecalculationAt).getTime();
                const percentage = 100 - (this.secondsLeft / ((nextRecalculationAt - lastRecalculationAt)/1000)) * 100;
                if (percentage > 100) return 100;
                if (percentage < 0) return 0;
                return percentage;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .bar_wrapper {
        position: relative;
        background: #CCC;
        border-radius: 5px;
        width:  100%;
        height: 10px;
        background: #DDD;
        .bar {
            position:absolute;
            left:0;
            top:0;
            height: 10px;
            border-radius: 5px;
            background: #FF0000;

        }
    }
</style>