<template>
<v-app>
            <v-navigation-drawer
            :mini-variant.sync="mini"
            permanent
            expand-on-hover
            app
            >

            <v-list dense>
                <v-list-item
                    link
                    @click="$router.push('/admin/dashboard')"
                >
                <v-list-item-icon>
                    <v-icon>mdi-view-dashboard-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Dashboard</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-list-item
                    link
                    @click="$router.push('/admin/reports')"
                >
                <v-list-item-icon>
                    <v-icon>mdi-alert-octagon-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Reported Rooms</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-list-item
                    link
                    @click="$router.push('/admin/challenges')"
                >
                <v-list-item-icon>
                    <v-icon>mdi-cube-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Suggested Challenges</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
                <v-list-item
                    link
                    @click="goToQueues()"
                >
                <v-list-item-icon>
                    <v-icon>mdi-bullseye-arrow</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Queues</v-list-item-title>
                </v-list-item-content>
                </v-list-item>

                <v-list-item
                    link
                    @click="$router.push('/admin/logs')"
                >
                <v-list-item-icon>
                    <v-icon>mdi-sticker-text-outline</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Logs</v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </v-list>
            </v-navigation-drawer>
    <v-main>
        <v-container>
            <router-view/>
        </v-container>
    </v-main>
</v-app>
</template>

<script>
    import { getUser } from "../../utils/login"
    import { StreamingServiceURL } from "../../utils/consts"
    export default {
        data() {
            return {
                mini: true,
            }
        },
        created() {
            if (getUser().email != 'superadmin@test.com') 
                this.$router.go('/');
        },
        methods: {
            goToQueues() {
                location.href = StreamingServiceURL() + '/queues';
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>