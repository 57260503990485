import kurentoUtils from 'kurento-utils'

export function viewer(params) {//streamId, video, socket, connectionId, callback) {

    var webRtcPeer;

    var localCandidateQueue = [];
    var remoteCandidateQueue = [];
    var messageQueue = [];

    var options = {
        remoteVideo: params.video,
        onicecandidate : (candidate) => {
            var message = {
               connectionId: params.connectionId,
               type : 'onIceCandidate',
               candidate : candidate
            } 
            if (webRtcPeer.peerConnection.signalingState == 'stable')
                sendMessage(message)
            else
                localCandidateQueue.push(message)
     },
        onaddstream: (x) => {
            params.callback({
                type: 'updateVideo',
                stream: x.stream
            })
        },
        configuration: {
            iceServers: [
                {urls: "stun:eu-turn7.xirsys.com"},
                {urls: [
                "turn:eu-turn7.xirsys.com:80?transport=udp",
                "turn:eu-turn7.xirsys.com:3478?transport=udp",
                "turn:eu-turn7.xirsys.com:80?transport=tcp",
                "turn:eu-turn7.xirsys.com:3478?transport=tcp",
                "turns:eu-turn7.xirsys.com:443?transport=tcp",
                "turns:eu-turn7.xirsys.com:5349?transport=tcp",
                ], 
                username: "ZRkFgKy84trjeBCuNBvdVy0_MaPpJK-Iq7t-cTiWDnTKS_6l0a4QZCm9YlEdmk_QAAAAAF9GHBxrb25la3RycnRj",
                credential: "7bf17ba0-e775-11ea-8c28-0242ac140004"},
            ]
        }
    }

    webRtcPeer = kurentoUtils.WebRtcPeer.WebRtcPeerRecvonly(options, function(error) {
        if(error) return console.error(error);

        this.generateOffer((error, offerSdp) => onOfferViewer(error, offerSdp));
    });

    function sendMessage(message) {
        var jsonMessage = JSON.stringify(message);
        if (params.socket == null || params.socket.readyState != 1) {
            messageQueue.push(jsonMessage)
        } else {
            params.socket.send(jsonMessage);
        }
    }

    function onOfferViewer(error, offerSdp) {
        if (error) return console.error(error);
    
        var message = {
            type : 'watch',
            sdpOffer : offerSdp,
            connectionId: params.connectionId,
            room: params.roomId
        }
        sendMessage(message);
    }

    function refreshVideo() {
        params.video.play()
    }

    setInterval(() => {
        sendMessage({
            type: 'ping'
        })
    }, 30 * 1000)

    
	function processAnswer(sdpAnswer) {
        console.log(sdpAnswer);
        webRtcPeer.processAnswer(sdpAnswer, (err) => {
            if (err)
                console.log('Process Answers error', err)
            remoteCandidateQueue.forEach(candidate => {
                webRtcPeer.addIceCandidate(candidate);
            })
            remoteCandidateQueue = []
            localCandidateQueue.forEach(m => sendMessage(m))
            localCandidateQueue = []
        });
    }

    function onIceCandidate(candidate) {
        if (webRtcPeer.peerConnection.signalingState == 'stable')
            webRtcPeer.addIceCandidate(candidate)
        else {
            remoteCandidateQueue.push(candidate)
        }
    }
    
    return {
        processAnswer,
        onIceCandidate,
        refreshVideo
    }
}