<template>
  <v-container style="height: 100%">
    <v-layout wrap align-center pa-16 style="height: 100%">
      <v-flex shrink style = "height:100%">
        <v-layout column style = "height:100%" align-center justify-center>
          <v-layout column style = "width:80%;margin: 0 auto;">
            <h1 style="font-weight: 100;" class = "text-left">
              {{ title }}
            </h1>
            <v-flex shrink>
              <v-layout wrap align-center>
                <v-flex shrink>
                  <v-icon color="red">mdi-account</v-icon>
                </v-flex>
                <v-flex shrink class="text--red">
                  {{ liveCount }}
                </v-flex>
                <v-spacer/>
                <v-flex shrink class="text--red">
                  {{ liveFor }}
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-spacer />
          <div class = "video_wrapper">
           <v-card class = "challenge" tile>
                <v-card-text >
                  <v-layout row align-center justify-center  v-if = "!challenge">
                    <v-btn plain @click = "createChallengeDialog.dialog = true">Create Challenge</v-btn>
                  </v-layout>
                  <challenge :data = "challenge" :stream = "stream" type = "VIEWER" v-else/>
                </v-card-text>
              </v-card>
            <v-layout column style = "height: 100%; position: relative">
              <v-flex v-for="(presenter, index) in stream.presenters" :key = "`video_${index}`" style = "position: relative">
                <video
                    id="video"
                    autoplay
                    style = "object-fit: cover;"
                    :ref="`video_${presenter.connectionId}`"
                    poster="https://s3.eu-central-1.amazonaws.com/ezmid.konektr.development/uploads/cc/10/cc1023c0-026e-11eb-a742-19aedb36cbbb-LogoHome.svg"
                  ></video>
                  <div style = "position: absolute; height:100%; width:100%; left:0; top:0; right:0; background: #000" :style = "presenter.media.video ? 'display:none' : 'display:block'"/>
                  <div style = "position: absolute; top: 15px; right: 15px; padding: 10px;">
                    <v-btn icon rounded dark v-if = "!presenter.media.audio">
                      <v-icon>
                        {{ presenter.media.audio ? 'mdi-microphone' : 'mdi-microphone-off' }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-list-item style = "position: absolute; bottom: 0; left:0; width:100%; background: rgba(255,255,255,.8)">
                    <v-list-item-avatar>
                      <v-avatar>
                        <v-img :src = "presenter.image"/>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ presenter.username }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ presenter.connectionId }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
              </v-flex>
            </v-layout>
          </div>
          <v-spacer />
        </v-layout>
        <div>
          <v-layout wrap>
            <v-flex xs3 v-for = "(challenge, index) in doneChallenges" :key="`stream_chall_${index}`">
              <v-card @click = "watchReplay(challenge)" class = "pa-3 ma-3 align-center justify-center">
                <v-layout column align-center justify-center>
                  <h3>{{index}}</h3>
                  <small>{{challenge.name}}</small>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </div>
      </v-flex>
      <v-flex grow style="padding: 30px; height: 100%; max-height: calc(100vh  - 216px); ">
        <v-layout wrap>
          <v-btn @click = "refresh" 
            outlined
            rounded> 
            Refresh
          </v-btn>
          <v-flex/>
          <v-btn @click = "shareDialog.visible = true" 
            outlined
            rounded> 
            Share
          </v-btn>
        </v-layout>
        <h4 style="font-weight: 100">Messages</h4>
        {{ creditBattle }}
        <v-card style="height: 100%">
          <v-layout column style="height: 100%">
            <v-layout column style="height: 100%; overflow: auto">
              <v-template v-for="(data, index) in sortedMessages" :key="index">
                                <v-layout
                  wrap
                  style="padding: 8px 16px"
                  align-center
                >
                  <v-flex shrink>
                    <v-layout wrap align-center>
                      <v-flex>
                        <v-avatar>
                          <img
                            :src = "data.user.image"
                            alt="John"
                          >
                        </v-avatar>
                      </v-flex>
                      <v-flex grow style = "padding-left: 16px">
                        <v-layout column>
                      <v-chip
                        :class="{
                          'gladient-bg': data.user.id === getUser().id,
                        }"
                      >
                        {{ data.user.username }}
                      </v-chip>
                      <div>  
                        {{ data.message }}
                      </div>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-template>
            </v-layout>
            <v-layout
              wrap
              style="
                padding-left: 16px;
                padding-right: 16px;
                padding-bottom: 16px;
              "
              align-center
              justify-center
            >
              <v-flex grow style="padding-right: 16px">
                <v-text-field
                  hide-details
                  label="Message"
                  v-model="message"
                  outlined
                  @keydown.enter="send"
                />
              </v-flex>
              <v-flex shrink>
                <v-btn class="gladient-bg mr-4" rounded icon @click="tip">
                  <v-icon color="white"> mdi-bitcoin </v-icon>
                </v-btn>
              </v-flex>
              <v-flex shrink>
                <v-btn class="gladient-bg" rounded icon @click="send">
                  <v-icon color="white"> mdi-send </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model = "tipDialog.show" :max-width="500">
      <v-card>
        <v-card-title>
          Send tip
        </v-card-title>
        <v-card-text>
          <v-layout wrap align-center justify-center>
            <v-flex class="text-center">
              <v-btn rounded @click="sendTip(5)">
                5
              </v-btn>
            </v-flex>
            <v-flex class="text-center">
              <v-btn rounded @click="sendTip(10)">
                10
              </v-btn>
            </v-flex>
            <v-flex class="text-center">
              <v-btn rounded @click="sendTip(15)">
                15
              </v-btn>
            </v-flex>
            <v-flex class="text-center">
              <v-btn rounded @click="sendTip(20)">
                20
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model = "entryFeeDialog.show" max-width="500">
      <v-card>
        <v-card-title>Entry fee</v-card-title>
        <v-card-text>This stream has entry fee {{entryFeeDialog.fee}} credits</v-card-text>
        <v-card-actions>
          <v-spacer/><v-btn primary @click="pay">Pay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model = "createChallengeDialog.dialog" max-width="500">
      <v-card>
        <v-card-title>Create Challenge</v-card-title>
        <v-card-text>
          <v-layout wrap>
              <v-chip v-for="(data, index) in suggestedChallenges" :key = "`chip_${index}`" :dark = "createChallengeDialog.name === data.name" @click = "createChallengeDialog.name = data.name">
                {{ data.name }}
              </v-chip>
          </v-layout>
          <v-text-field v-model = "createChallengeDialog.name" solo-inverted class="mt-4"/>
          <v-text-field v-model.number = "createChallengeDialog.credits" placeholder="Credits" solo-inverted class="mt-4"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/><v-btn primary @click="createChallenge">Send</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class = "snack" :class = "{ visible: snack.visible }">
      <v-card-text>
        {{ snack.text }}
      </v-card-text>
    </v-card>
    <v-dialog v-model="shareDialog.visible" max-width="500">
      <v-card>
        <v-card-title>
          Share stream
        </v-card-title>
        <v-card-text>
          <div>
            <v-text-field
                outlined
                v-model="shareDialog.shareLink"
                :readonly="true"
                append-icon="mdi-content-copy"
                @click="copy"
              ></v-text-field>
          </div>
          <v-layout wrap>
            <v-flex xs3 pa-2>
              <v-card :elevation="6" @click="instagramShare">
                <v-card-text class="pt-8 pb-8 text-center">
                  <v-icon>
                    mdi-instagram
                  </v-icon>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model = "replayDialog" max-width="590">
      <v-card>
                <video-player 
                  ref="videoPlayer"
                  :options = "streamOptions"
                  class="vjs-custom-skin"
                  id="video_preview" />
      </v-card>
    </v-dialog>
  <div class = "animation_wrapper">
    <div class = "started" :class = "{ shown:  animation.type === 'challengeStarted' && animation.shown }">
      <div class = "data_wrapper" v-if = "animation.type === 'challengeStarted'">
        <v-layout row>
          <v-flex grow class = "data_wrapper_bg green first">
            Live challenge
          </v-flex>
          <v-flex shrink class = "data_wrapper_bg white second">
            +{{ animation.data.credits }}
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex grow class = "data_wrapper_bg offset orange third">
            {{ animation.data.name }}
          </v-flex>
        </v-layout>
      </div>
    </div>
    <div class = "ended" :class = "{ shown:  animation.type === 'challengeEnded' && animation.shown }">
      <div class = "data_wrapper" v-if = "animation.type === 'challengeEnded'">
        <v-layout row>
          <v-flex grow class = "data_wrapper_bg green first">
            Challenge
          </v-flex>
          <v-flex shrink class = "data_wrapper_bg white second">
            +{{ animation.data.credits }}
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex grow class = "data_wrapper_bg offset orange third">
            {{ animation.data.successful ? 'Passed' : 'Failed' }}
          </v-flex>
        </v-layout>
      </div>
    </div>
  </div>
  </v-container>
</template>
<script>
import axios from "axios";
import { URL } from "../utils/consts";
import { refreshVideos, setupViewer, join, chatMessage, stop, setupCallback, leave, setSocket, getMediaStream, userStates } from "../plugins/websocket";
import { getUser } from '../utils/login'
import moment from 'moment';
import challenge from '../components/Challenge.vue'
export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    challenge
  },
  data() {
    return {
      stream: null,
      recording: null,
      message: '',
      liveFor: 'Offline',
      snack: {text: '', visible: false},
      messages: [
      ],
      creditBattle: null,
      animation: {
        type: null,
        shown: false,
        data: {
        }
      },
      streamOptions: {
          preload: true,
          autoplay: true,
          isLoop: true,
          playsinline: false,
          crossOrigin: 'anonymous',
          type: 'application/x-mpegurl',
      },
      createChallengeDialog: {
        dialog: false,
        name: 'Test Chall',
        credits: 0,
      },
      challenge: null,
      tipDialog:{
        show: false
      },
      entryFeeDialog: {
        show: false,
        fee: 0
      },
      shareDialog: {
        visible:false,
        shareLink: ''
      },
      toggledCamera: true,
      presenters: [],
      suggestedChallenges: [],
      replayDialog: false,
      replay: null
    };
  }, 
  methods: {
    watchReplay(challenge) {
      this.replay = this.link(challenge.video);
      this.replayDialog = true;
      setTimeout(() => {
        this.streamOptions.src = this.replay;
        console.log(this.$refs['videoPlayer']);
        console.log(this.$refs['videoPlayer'].player);
        this.$refs['videoPlayer'].player.src(this.streamOptions)
        this.$refs['videoPlayer'].player.play()
        console.log(this.replay);
        }, 1000)
    },
    refresh() {
      refreshVideos()
    },
    async createChallenge() {
      await axios.post(URL() + `rooms/${this.stream.id}/challenge`, {
        name: this.createChallengeDialog.name,
        credits: this.createChallengeDialog.credits,
      }, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    async tip() {
      this.tipDialog.show=true
    },
    async pay() {
      await axios.get(URL() + `rooms/${this.stream.id}/pay`, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
      this.entryFeeDialog.show = false
      this.fetchStreams()
    },
    computeLiveFor() {
      if (!this.stream?.live) return 'Offline';
      if (this.stream?.sessions?.length == 0) return 'Offline';
      const diffInSeconds = this.stream?.sessions?.map((session) => {
          if (session.duration) return session.duration;
          return moment().diff(session.start, 'seconds', true);
        }).reduce((a,b) => a + b, 0)
      const seconds = Math.floor(diffInSeconds % 60);
      const minutes = Math.floor(diffInSeconds / 60 % 60);
      const hours = Math.floor(diffInSeconds / 60 / 24 % 24);
      return `${this.appendZeroUnderTen(hours)}:${this.appendZeroUnderTen(minutes)}:${this.appendZeroUnderTen(seconds)}`
    },
    appendZeroUnderTen(value) {
      if (value < 10) return `0${value}`
      else return value
    },
    async fetchStreams() {
      const { data } = await axios.get(URL() + "rooms", {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      });
      const { id } = this.$route.params;
      console.log(data);
      const stream = data.items.find((s) => s.id === parseInt(id));
      console.log(stream);
      this.stream = stream;
      this.shareDialog.shareLink = this.stream.shareUrl // `${url}/publisher/${this.stream.userId ?? 0}/stream`
      this.shareDialog.download = {
        url: `${URL()}rooms/${this.stream.id}/cover?auth=${getUser().token}`,
        filename: this.stream.cover.video ? 'video.mp4' : 'cover.jpg'
      }
      const refferer = this.$route.query.ref;
      join(stream.id, 'viewer', refferer);
    },
    async fetchSuggestedChallenges() {
      const { data } = await axios.get(URL() + "rooms/challenge/suggested", {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      });
      this.suggestedChallenges = data;
    },
    async getChatHistory() {
      try {
        const { data: chatHistory } = await axios.get(URL() + "rooms/" + this.stream.id + "/chat", {
          headers: {
            Authorization: "Bearer " + getUser().token,
          }
        });
        chatHistory.items.forEach(message => {
          this.handleCallback(message);
        })
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          this.getChatHistory()          
        }, 1000)
      }
    },
    async sendTip(count) {
      try {
        await axios.post(URL() + `rooms/${this.stream.id}/tip`, {
          count
        }, {
          headers: {
            Authorization: "Bearer " + getUser().token,
          }
        });
        alert("Successfully sent a tip")
        this.tipDialog.show = false
      } catch (e) {
        alert(e.response.data.msg)
        this.tipDialog.show = false
      }
    },
    getUser() {
      return getUser()
    },
    play(recording) {
        this.recording = URL() + "/recordings/" + recording;
        this.$refs.video.src = this.recording;
        this.$refs.video.play()
    },
    send() {
      const msg = this.message;
      chatMessage(msg)
      this.message = '';
    },
    copy() {
      navigator.clipboard.writeText(this.shareDialog.shareLink)
      alert('Copied!')
    },
    instagramShare() {
      var a = document.createElement("a");
      a.href = this.shareDialog.download.url;
      const filename = this.shareDialog.download.filename
      console.log(filename);
      a.setAttribute("download", filename);
      a.click();
    },
    handleCallback(message) {
        console.log(message)
        if (message.type === 'ready') {
          this.presenters.push(message);
          setTimeout(() => {
            console.log(this.$refs);
            setupViewer(message.id, message.connectionId, this.$refs['video_'+message.connectionId][0], this.handleCallback)
          }, 1000)
        }
        if (message.type === 'pause') {
          if (!message.connectionId) {
            this.presenters.splice(0);
          } else {
            const index = this.presenters.findIndex(presenter => presenter.connectionId === message.connectionId)
            console.log('Going to remove ', index, 'from ', this.presenters);
            if (index >= 0)
              this.presenters.splice(index, 1);
          }
        }
        if (message.type === 'message') {
          this.messages.push({
              ...message,
              user: message.sender
          })
        }
        if (message.type === 'pause') {
          this.snack.text = 'Publisher left: ' + message.reason;
          this.snack.visible = true;
          setTimeout(() => {
            this.snack.visible = false;
          }, 5000);
        }
        if (message.type ===  'joined') {
          this.getChatHistory();
        }
        if (message.type === 'notification') {
          console.log(message)
          if (message.notificationType === 'leave') {
            this.messages.push({
              ...message,
              user: message.data.user,
              message: 'has left'
            })
          } else if (message.notificationType === 'join') {
            this.messages.push({
              ...message,
              user: message.data.user,
              message: 'has joined'
            })
          } else if (message.notificationType === 'tip') {
            this.messages.push({
              ...message,
              user: message.data.user,
              message: `has sent a tip ${message.data.amount} credits` 
            })
          } else if (message.notificationType === 'challenge') {
            this.messages.push({
              ...message,
              user: message.data.user,
              challenge: message.data.challenge,
              message: `created challenge ${message.data.challenge.name}` 
            })
          } else if (message.notificationType === 'presenterVideo') {
            this.toggledCamera = message.data.enabled
          }
        }
        if (message.type === 'challengeStarted') {
          this.animation.data = message.challenge
          this.animation.type = 'challengeStarted'
          setTimeout(() => {
            this.animation.shown = true;
            setTimeout(() => {
              this.animation.shown = false;
              setTimeout(() => {
                this.animation.type = null;
              }, 1000);
            }, 1500)
          }, 100)
        }
        if (message.type === 'challengeEnded') {
          this.animation.data = {
            successful: message.successful,
            ...message.challenge
          }
          this.animation.type = 'challengeEnded'
          setTimeout(() => {
            this.animation.shown = true;
            setTimeout(() => {
              this.animation.shown = false;
              setTimeout(() => {
                this.animation.type = null;
              }, 1000);
            }, 1500)
          }, 100)
        }
        if (message.type === "update") {
          this.stream = message.room;
          this.challenge = this.stream?.challenges?.find((challenge) => challenge.status === 'ACTIVE')
        }
        if( message.type === "rejected") {
          this.$router.go(-1)
        }
        if (message.type === "updateVideo") {
          // Requires user input !!!
          setTimeout(() => {
            getMediaStream()
          }, 2000)
        }
        if (message.type === "error") {
          console.log(message);
          if (message.data.errorType === 'entryFeeMessage') {
            this.entryFeeDialog.fee = message.fee;
            this.entryFeeDialog.show = true;
          }
        }
        if ([
            'creditBattleUpdate',
            'creditBattleStarted',
            'creditBattleEnded',
            'creditBattlePrepare',].includes(message.type)) {
              this.creditBattle = message.data;
            }
    },
    link(stream) {
      let link = stream.url + '?';
      link = link + stream.cookies.map(e => `${e.name.replace('CloudFront-','')}=${e.value}`).join('&')
      return link
    }
  },
  mounted() {
    setInterval(() => {
      this.liveFor = this.computeLiveFor();
    }, 1000);
    setTimeout(() => {
    setSocket();
      setupCallback((message) => {
        this.handleCallback(message)
      });
      this.fetchStreams();
      this.fetchSuggestedChallenges();
      userStates([...Array(500).keys()])
      }, 1000);
    },
  computed: {
    doneChallenges() {
      console.log(this.stream?.challenges)
      return (this.stream?.challenges ?? []).filter ((challenge) => (challenge.status === 'ENDED' || challenge.status === 'CANCELED') && challenge.video)
    },
    recordings() {
      return this.stream?.recordings ?? []
    },
    title() {
      return this.stream?.name ?? "Pending...";
    },
    liveCount() {
      return this.stream?.viewers ?? 0;
    },
    sortedMessages() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.messages.sort((a, b) => {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })
    },
  },
  beforeDestroy() {
    console.log('Calling before destroy')
    stop();
    leave();
  },
};
</script>
<style lang="scss">
.btn {
  background: linear-gradient(121.03deg, #55b6e2 15.79%, #604fe6 64.07%);
  border-radius: 30px;
  color: #FFF;
}
.gladient-bg {
  background: linear-gradient(121.03deg, #55b6e2 15.79%, #604fe6 64.07%) !important;
  color: #FFF!important;
}
#video {
  width: 100%;
  height: 100%;
  background-color: #333;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
}
.snack {
  position: fixed;
  margin: 0 auto;
  transform: translateY(230px);
  transition: all .5s;
}
.snack.visible {
  transform: translateY(-230px);
}
.video_wrapper {
  width: 590px;
  height: 890px;
  background: #000;
  opacity:1;
  transition: all .3s;
}
.invisible {
  opacity: 0;
}
.animation_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    .data_wrapper_bg {
      position: relative;
      font-size: 41px;
      &::before {
        color: rgba(0, 0, 0, 0.3) !important;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.5);
        z-index: -1;
        transform: skew(-40deg, 0deg);
        border: 1px solid #000;
      }
      &.green {
        &::before {
          border-color: #95D114;
        }
        color: #95D114;
        background-color: rgba(0,0,0,0) !important;
      }
      &.orange {
        &::before {
          border-color: #EFC11F;
        }
        color: #EFC11F;
        background-color: rgba(0,0,0,0) !important;
      }
      &.white {
        &::before {
          border-color: #FFF;
        }
        color: #FFF;
        background-color: rgba(0,0,0,0) !important;
      }
      
      padding: 10px 45px;
      margin: 5px;

      transition: all .5s;

      &.first {
        transform: translateX(-100vw);
      }
      &.second {
        transform: translateX(100vw);
      }
      &.third {
        transform: translateX(-100vw);
      }
    }
    
    .shown {
      .second,
      .first {
        transform: translateX(0px)
      }
      .third {
        transform: translateX(-72px)
      }
    }
}
</style>