<template>
  <v-container style="height: 100%">
    <v-layout wrap align-center pa-16 style="height: 100%">
      <v-flex shrink style = "height:100%">
        <v-layout column style = "height:100%">
          <h1 style="font-weight: 100">
            {{ title }}
          </h1>
          <v-flex shrink>
            <v-layout wrap align-center>
              <v-flex shrink>
                <v-icon color="red">mdi-account</v-icon>
              </v-flex>
              <v-flex shrink class="text--red">
                {{ liveCount }}
              </v-flex>
              <v-spacer/>
              <v-flex shrink class="text--red">
                {{ liveFor }}
              </v-flex>
            </v-layout>
          </v-flex>
          <v-spacer />
          <div class = "video_wrapper">
          <v-layout column style = "height: 100%; position: relative;">
              <v-card class = "challenge" tile>
                <v-card-text >
                  <v-layout row align-center justify-center  v-if = "!challenge">
                    <v-btn plain @click = "createChallengeDialog.dialog = true">Create Challenge</v-btn>
                  </v-layout>
                  <challenge :data = "challenge" :stream = "stream" type = "PRESENTER" v-else/>
                </v-card-text>
              </v-card>
              <video
                      id="video"
                      class = "main"
                      :class = "{
                        small: presenters.length
                      }"
                      autoplay
                      width="590px"
                      height="890px"
                      style = "object-fit: cover; z-index: 100"
                      ref="video"
                poster="https://s3.eu-central-1.amazonaws.com/ezmid.konektr.development/uploads/cc/10/cc1023c0-026e-11eb-a742-19aedb36cbbb-LogoHome.svg"
              ></video>
              <v-flex v-for="(presenter, index) in presenting" :key = "`video_${index}`" style = "position: relative">
                <video
                    id="video"
                    autoplay
                    style = "object-fit: cover;"
                    :ref="`video_${presenter.connectionId}`"
                    poster="https://s3.eu-central-1.amazonaws.com/ezmid.konektr.development/uploads/cc/10/cc1023c0-026e-11eb-a742-19aedb36cbbb-LogoHome.svg"
                  ></video>
                  <div style = "position: absolute; height:100%; width:100%; left:0; top:0; right:0; background: #000" :style = "presenter.media.video ? 'display:none' : 'display:block'"/>
                  <div style = "position: absolute; top: 15px; right: 15px; padding: 10px;">
                    <v-btn icon rounded dark @click = "mute(presenter)">
                      <v-icon>
                        {{ presenter.media.audio ? 'mdi-microphone' : 'mdi-microphone-off' }}
                      </v-icon>
                    </v-btn>
                  </div>
                  <v-list-item style = "position: absolute; bottom: 0; left:0; width:100%; background: rgba(255,255,255,.8)">
                    <v-list-item-avatar>
                      <v-avatar>
                        <v-img :src = "presenter.image"/>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ presenter.username }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ presenter.connectionId }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
              </v-flex>
            </v-layout>
          </div>
          <v-btn
            @click="toggleStream"
            style="margin-top: 16px; width: 100%"
            class="btn"
            dark
            >{{ playing ? "Stop the stream" : "Start streaming" }}</v-btn
          >
          <v-spacer />
        </v-layout>
      </v-flex>
      <v-flex grow style="padding: 30px; height: 100%; max-height: calc(100vh - 216px); ">
        <v-layout wrap>
          <v-btn @click = "invitationDialog.shown = true" 
            outlined
            rounded> 
            Invite
          </v-btn>
          <v-btn @click = "refresh" 
            outlined
            rounded> 
            Refresh
          </v-btn>
          <v-btn @click = "toggleMute(null)" 
            outlined
            rounded> 
            {{ muted ? 'Unmute' : 'Mute' }}
          </v-btn>
          <v-flex/>
          <v-btn @click = "shareDialog.visible = true" 
            outlined
            rounded> 
            Share
          </v-btn><v-btn class="ml-2" @click = "toggleCamera" 
            outlined
            rounded> 
            Camera {{ toggledCamera ? 'on' : 'off' }}
          </v-btn>
          <v-btn class="ml-2" @click = "statsDialog.visible = true" 
            outlined
            rounded> 
            {{ stats.allTime.tips + stats.allTime.entryFees + stats.allTime.challenges }} credits
          </v-btn>
        </v-layout>
        <h4 style="font-weight: 100">Messages</h4>
        {{ creditBattle }}
        <v-card style="height: 100%">
          <v-layout column style="height: 100%">
            <v-layout column style="height: 100%; overflow: auto">
              <v-template v-for="(data, index) in sortedMessages" :key="index">
                                <v-layout
                  wrap
                  style="padding: 8px 16px"
                  align-center
                >
                  <v-flex shrink>
                    <v-layout wrap align-center>
                      <v-flex>
                        <v-avatar>
                          <img
                            :src = "data.user.image"
                            alt="John"
                          >
                        </v-avatar>
                      </v-flex>
                      <v-flex grow style = "padding-left: 16px">
                        <v-layout column>
                      <v-chip
                        :class="{
                          'gladient-bg': data.user.id === getUser().id,
                        }"
                      >
                        {{ data.user.username }}
                      </v-chip>
                      <div>  
                        {{ data.message }}
                      </div>
                      <div v-if = "data.challenge">
                        <v-btn @click = "acceptChallenge(data.challenge)">Accept</v-btn>
                        <v-btn @click = "rejectChallenge(data.challenge)">Reject</v-btn>
                        </div>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-template>
            </v-layout>
            <v-layout
              wrap
              style="
                padding-left: 16px;
                padding-right: 16px;
                padding-bottom: 16px;
              "
              align-center
              justify-center
            >
              <v-flex grow style="padding-right: 16px">
                <v-text-field
                  hide-details
                  label="Message"
                  v-model="message"
                  outlined
                  @keydown.enter="send"
                />
              </v-flex>
              <v-flex shrink>
                <v-btn class="gladient-bg" rounded icon @click="send">
                  <v-icon color="white"> mdi-send </v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="createChallengeDialog.dialog" max-width = "500">
      <v-card>
        <v-card-title>
          <v-layout column>
          <v-text-field v-model = "createChallengeDialog.name" label="Name"/>
          <v-text-field v-model.number = "createChallengeDialog.credits" label="Credits"/>
          </v-layout>
        </v-card-title>
        <v-card-actions>
          <v-layout row>
            <v-spacer/><v-btn @click="createChallenge">Create</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statsDialog.visible" max-width="500">
      <v-card>
        <v-card-title>
          <v-layout wrap align-center justify-center>
            <v-btn-toggle
                    v-model="toggled"
                    shaped
                    mandatory
                  >
                    <v-btn>
                      All Time
                    </v-btn>

                    <v-btn>
                      Current Session
                    </v-btn>                  
                  </v-btn-toggle>
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-if = "toggled === 0">
              <v-list-item-avatar>
                <v-icon>mdi-bitcoin</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.allTime.tips }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Tips
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-avatar>
                <v-icon>mdi-bitcoin</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.session.tips }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Tips
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if = "toggled === 0">
              <v-list-item-avatar>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.allTime.entryFees }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Entry fees
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-avatar>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.session.entryFees }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Entry fees
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item v-if = "toggled === 0">
              <v-list-item-avatar>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.allTime.challenges }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Challenges
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else>
              <v-list-item-avatar>
                <v-icon>mdi-currency-usd</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ stats.session.challenges }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Challenges
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="shareDialog.visible" max-width="500">
      <v-card>
        <v-card-title>
          Share stream
        </v-card-title>
        <v-card-text>
          <div>
            <v-text-field
                outlined
                v-model="shareDialog.shareLink"
                :readonly="true"
                append-icon="mdi-content-copy"
                @click="copy"
              ></v-text-field>
          </div>
          <v-layout wrap>
            <v-flex xs3 pa-2>
              <v-card :elevation="6" @click="instagramShare">
                <v-card-text class="pt-8 pb-8 text-center">
                  <v-icon>
                    mdi-instagram
                  </v-icon>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card class = "creditBattleReadyCheck" v-if = "creditBattle != null && creditBattle.status === 'PENDING'">
      <v-card-title>Ready Check!</v-card-title>
      <v-card-text v-if = "creditBattle.goal">
        First to collect {{ creditBattle.goal }} credits wins
      </v-card-text>
      <v-card-text v-else>
        The one that collects the most in 10 minutes wins
      </v-card-text>
      <v-list rounded>
        <v-list-item v-for = "attendee in creditBattle.attendees" :key = "attendee.id">
          <v-list-item-avatar>
            <v-img :src="attendee.image"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
          <v-list-item-title v-text="attendee.username"></v-list-item-title>

            <v-list-item-subtitle>{{ attendee.status === 'PENDING' ? 'Not ready' : 'Ready' }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action v-if = "attendee.id === meInCreditBattle.id && attendee.status === 'PENDING'">
            <v-btn icon @click = "readyBattle">
              <v-icon color="green lighten-1">mdi-check</v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action v-else>
            <v-icon color="green lighten-1" v-if = "attendee.status === 'READY'">mdi-check</v-icon>
            <v-icon color="orange lighten-1" v-else>mdi-help</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-card-text v-if = "allReady">
        All ready! Starts in: {{allReadyCountdown}}s
      </v-card-text>
    </v-card>
    <v-card class = "creditBattleReadyCheck" v-if = "creditBattle != null && creditBattle.status === 'ACTIVE'">
      <v-card-title>Credit Battle!</v-card-title>
      <v-card-text v-if = "creditBattle.goal">
        First to collect {{ creditBattle.goal }} credits wins
      </v-card-text>
      <v-card-text v-else>
        The one that collects the most in 10 minutes wins
      </v-card-text>
      <v-list rounded>
        <v-list-item v-for = "attendee in creditBattle.attendees" :key = "attendee.id">
          <v-list-item-avatar>
            <v-img :src="attendee.image"></v-img>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title v-text="attendee.username"></v-list-item-title>
            <v-list-item-subtitle>{{ attendee.credits }} c</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
    <v-dialog v-model = "invitationDialog.shown" max-width="500">
      <v-card>
        <v-card-title>Invitations</v-card-title>
        <v-list>
          <v-list-item v-for = "invite in stream.invitations" :key = "invite.id">
            <v-list-item-avatar>
              <v-img :src="invite.user.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="invite.user.username"></v-list-item-title>
              <v-list-item-subtitle>{{ invite.status }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if = "invite.user.id === getUser().id && invite.status === 'PENDING'">
              <v-btn icon @click = "updateInvite('accept')">
                <v-icon color="green lighten-1">mdi-check</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action v-if = "invite.user.id === getUser().id && invite.status === 'PENDING'">
              <v-btn icon @click = "updateInvite('reject')">
                <v-icon color="red lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action v-else>
              <v-icon color="green lighten-1" v-if = "invite.status === 'ACCEPTED'">mdi-check</v-icon>
              <v-icon color="orange lighten-1" v-else-if = "invite.status === 'PENDING'">mdi-help</v-icon>
              <v-icon color="red lighten-1" v-else>mdi-close</v-icon>
            </v-list-item-action>
            <v-list-item-action v-if = "stream.userId === getUser().id && (invite.status === 'ACCEPTED' || invite.status === 'PENDING')">
              <v-btn icon @click = "kickUser(invite)">
                <v-icon color="red lighten-1">mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-list-item @click = "inviteNewDialog.shown = true">
            <v-list-item-avatar>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Invite new user</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
    <v-dialog v-model = "inviteNewDialog.shown" max-width="500">
      <v-card>
        <v-card-title>New invitation</v-card-title>
        <v-card-text>
          <v-text-field v-model = "inviteNewDialog.search" placeholder = "Search" rounded filled/>
        </v-card-text>
        <v-list>
          <v-list-item v-for = "user in filteredPublishers" :key = "user.id">
            <v-list-item-avatar>
              <v-img :src="user.image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="user.username"></v-list-item-title>
              <v-list-item-subtitle v-text="user.email"></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action v-if = "isInvited(user.id)">
              <v-btn icon @click = "removeInvitedUser(user)">
                <v-icon color="red lighten-1">mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-action v-else>
              <v-btn icon @click = "inviteUser(user)">
                <v-icon color="green lighten-1">mdi-plus</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-text>
          <v-layout column>
            <v-layout row align-center>
              <h3>Credit Battle</h3>
              <v-spacer></v-spacer>
              <v-switch
                v-model="inviteNewDialog.hasCreditBattle"
              ></v-switch>
            </v-layout>
            <v-flex v-if = "inviteNewDialog.hasCreditBattle">
              <v-text-field v-model.number = "inviteNewDialog.creditBattleGoal"></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click = "createInvite">Invite</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  presenter,
  startRecording,
  stop,
  stopRecording,
  chatMessage,
  videoFeed,
  join, 
  leave,
  setupCallback,
  setSocket,
  toggleCamera,
  setupViewer,
  refreshVideos,
  sendMessage,
} from "../plugins/websocket";
import { URL } from "../utils/consts";
import moment from 'moment';
import { getUser } from "../utils/login";
import axios from 'axios';
import challenge from '../components/Challenge.vue'
import { v4 } from 'uuid';
export default {
  components: {
    challenge
  },
  data() {
    return {
      myId: "",
      message: "",
      playing: false,
      recording: false,
      toggled: 0,
      liveFor: 'Offline',
      statsDialog: {
        visible: false
      },
      createChallengeDialog: {
        dialog: false,
        name: 'Test Chall',
        goalPledge: 1000,
        minPledge: 100,
        private: false,
        startsIn: 10,
        duration: 10,
        entryFee: 0,
      },
      invitationDialog: {
        shown: false,
        invites: []
      },
      inviteNewDialog: {
        shown: false,
        search: '',
        publishers: [],
        invitees: [],
        hasCreditBattle: false,
        creditBattleGoal: 0
      },
      allReady: false,
      allReadyCountdown: 5,
      toggledCamera: true,
      messages: [],
      stream: {
        name: "Pending...",
      },
      challenge: null,
      stats: {
        allTime: {
          tips: 0,
          entryFees: 0,
        },
        session: {
          tips: 0,
          entryFees: 0,
        }
      },
      creditBattle: null,
      shareDialog: {
        visible:false,
        shareLink: ''
      },
      muted: false,
      presenters: [],
      
    };
  },
  computed: {
    title() {
      return this.stream?.name ?? "Pending...";
    },
    liveCount() {
      return this.stream?.viewers ?? 0;
    },
    sortedMessages() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      return this.messages.sort((a, b) => {
          return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      })
    },
    presenting() {
      return this.stream.presenters ? this.stream.presenters.filter((p) => p.connectionId != this.myId) : []
    },
    meInCreditBattle() {
      if (!this.creditBattle) return;
      return this.creditBattle.attendees.find((u) => u.id === getUser().id);
    },
    filteredPublishers() {
      if (!this.inviteNewDialog.search) return this.inviteNewDialog.publishers;
      return this.inviteNewDialog.publishers.filter(p => p.username.toLowerCase().includes(this.inviteNewDialog.search.toLowerCase()))
    }
  },
  created() {
    this.myId = v4()
    setInterval(() => {
      this.liveFor = this.computeLiveFor();
    }, 1000);
    setTimeout(() => {
    setSocket(this.myId)
    setupCallback((message) => {
        this.handleCallback(message);
      })
        // TODO remake
      join(parseInt(this.$route.params.id), 'presenter')
      
      this.getChatHistory();
    }, 1000)
    axios.get(`${URL()}rooms/${parseInt(this.$route.params.id)}`, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      }).then(response => {
        const { data } = response;
        this.shareDialog.shareLink = data.shareUrl
        this.stream = data;
      })
    axios.get(`${URL()}publishers?limit=10000`, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      }).then(response => {
        const { data } = response;
        this.inviteNewDialog.publishers.push(...data);
      })
  },
  methods: {
    send() {
      const msg = this.message;
      chatMessage(msg);
      this.message = "";
    },
    refresh() {
      refreshVideos();
    },
    toggleCamera() {
      this.toggledCamera = !this.toggledCamera; 
      toggleCamera(this.toggledCamera)
    },
    isInvited(id) {
      return !!this.inviteNewDialog.invitees.find(i => i.id === id);
    }, 
    inviteUser(user) {
      this.inviteNewDialog.invitees.push(user);
    },
    removeInvitedUser(user) {
      this.inviteNewDialog.invitees = this.inviteNewDialog.invitees.filter (i => i.id !== user.id)
    },
    async createInvite() {
      let battle = null;
      if (this.inviteNewDialog.hasCreditBattle) {
        battle = {
          goal: null
        };
        if (this.inviteNewDialog.creditBattleGoal > 0) {
          battle.goal = this.inviteNewDialog.creditBattleGoal;
        }
      }
      await axios.post(URL() + `rooms/${parseInt(this.$route.params.id)}/invite`, {
        userIds: this.inviteNewDialog.invitees.map(i => i.id),
        battle
      }, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })

    },
    async acceptChallenge(challenge) {
      await axios.post(URL() + `rooms/challenge/${challenge.id}/accept`, null, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    async rejectChallenge(challenge) {
      await axios.post(URL() + `rooms/challenge/${challenge.id}/reject`, null, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    async cancelChallenge(challenge) {
      await axios.post(URL() + `rooms/challenge/${challenge.id}/cancel`, null, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    updateInvite(type) {
      axios.post(URL() + `rooms/${parseInt(this.$route.params.id)}/invite/${type}`, null, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    kickUser(invite) {
      axios.delete(URL() + `rooms/${parseInt(this.$route.params.id)}/invite/${invite.user.id}`, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    readyBattle() {
       axios.post(URL() + `rooms/battle/${this.creditBattle.id}/accept`, null, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    },
    computeLiveFor() {
      if (!this.stream?.live) return 'Offline';
      if (this.stream?.sessions?.length == 0) return 'Offline';
      const diffInSeconds = this.stream?.sessions?.map((session) => {
          if (session.duration) return session.duration;
          return moment().diff(session.start, 'seconds', true);
        }).reduce((a,b) => a + b, 0)
      const seconds = Math.floor(diffInSeconds % 60);
      const minutes = Math.floor(diffInSeconds / 60 % 60);
      const hours = Math.floor(diffInSeconds / 60 / 24 % 24);
      return `${this.appendZeroUnderTen(hours)}:${this.appendZeroUnderTen(minutes)}:${this.appendZeroUnderTen(seconds)}`
    },
    appendZeroUnderTen(value) {
      if (value < 10) return `0${value}`
      else return value
    },
    getUser() {
      return getUser();
    },
    toggleStream() {
      console.log(this.$route.params);
      this.playing = !this.playing;
      if (!this.playing) {
        stop();
        this.recording = false;
      } else {
        videoFeed(this.$refs.video);
        presenter(parseInt(this.$route.params.id));
      }
    },
    check() {
      if (this.titleInput.value) this.titleInput.error = "";
    },
    toggleRecording() {
      if (this.playing) this.recording = !this.recording;
    },
    copy() {
      navigator.clipboard.writeText(this.shareDialog.shareLink)
      alert('Copied!')
    },
    instagramShare() {
      var a = document.createElement("a");
      a.href = this.shareDialog.download.url;
      a.click();
    },
    async getChatHistory() {
      try {
        const { data: chatHistory } = await axios.get(URL() + "rooms/" + this.$route.params.id + "/chat", {
          headers: {
            Authorization: "Bearer " + getUser().token,
          }
        });
        chatHistory.items.forEach(message => {
          this.handleCallback(message);
        })
      } catch (e) {
        console.log(e);
        setTimeout(() => {
          this.getChatHistory()          
        }, 1000)
      }
    },
    mute({ connectionId }) {
      sendMessage({
        type: 'muteRequest',
        connectionId
      })
    },
    toggleMute(enabled = null) {
      if (enabled != null) {
        this.muted = enabled
      } else {
        this.muted = !this.muted
      }
      this.$refs['video'].srcObject.getAudioTracks().forEach(track => {
        console.log('Track', track)
        track.enabled = !this.muted
      })
      sendMessage({
        data: {
          enabled: !this.muted
        },
        notificationType:"presenterAudio",
        type:"notification"
      })
    },
    handleCallback(message) {
      console.log(message);
      if (message.type === 'message') {
        this.messages.push({
            ...message,
            user: message.sender
        })
      }
      if (message.type === 'ready') {
          this.presenters.push(message);
          setTimeout(() => {
            console.log(this.$refs);
            setupViewer(message.id, message.connectionId, this.$refs['video_'+message.connectionId][0], this.handleCallback)
          }, 1000)
        }
        if (message.type === 'pause') {
          const index = this.presenters.findIndex(presenter => presenter.connectionId === message.connectionId)
          if (index >= 0)
            this.presenters.splice(index, 1);
        }
      if (message.type === 'notification') {
        console.log('message', message)
        if (message.notificationType === 'leave') {
          this.messages.push({
            ...message,
            user: message.data.user,
            message: 'has left'
          })
        } else if (message.notificationType === 'join') {
          this.messages.push({
            ...message,
            user: message.data.user,
            message: 'has joined'
          })
        } else if (message.notificationType === 'tip') {
          this.messages.push({
            ...message,
            user: message.data.user,
            message: `has sent you a tip ${message.data.amount} credits` 
          })
        } else if (message.notificationType === 'challenge') {
            this.messages.push({
              ...message,
              user: message.data.user,
              challenge: message.data.challenge,
              message: `created challenge ${message.data.challenge.name}` 
            })
          }
      }
      if( message.type === "rejected") {
        this.$router.go(-1) 
      }
      if( message.type === "muteRequest") {
        this.toggleMute(true)
      }
      if (message.type === "update") {
        this.stream = message.room;
        this.challenge = this.stream?.challenges?.find((challenge) => challenge.status === 'ACTIVE')
        this.shareDialog.download = {
          url: `${URL()}rooms/${this.stream.id}/cover?auth=${getUser().token}`
        }
        if (message.stats)
          this.stats = message.stats;
      }
      if ([
          'creditBattleUpdate',
          'creditBattleStarted',
          'creditBattleEnded',
          'creditBattlePrepare',].includes(message.type)) {
            this.creditBattle = message.battle;
          }
      if (message.type === "creditBattlePrepare") {
        this.allReadyCountdown = 5;
        this.allReady = true;
        const interval = setInterval(() => {
          this.allReadyCountdown--;
          if (this.allReadyCountdown == 0) clearInterval(interval);
        }, 1000)
      }
      if (message.type === "creditBattleStarted") {
        this.allReady = false;
      }
    },
    async createChallenge() {
      await axios.post(URL() + `rooms/${this.stream.id}/challenge`, {
        name: this.createChallengeDialog.name,
        credits: this.createChallengeDialog.credits,
      }, {
        headers: {
          Authorization: "Bearer " + getUser().token,
        }
      })
    }
  },
  beforeDestroy() {
    stop();
    leave();
  },
  watch: {
    recording: {
      handler() {
        if (this.recording) {
          startRecording();
        } else {
          stopRecording();
        }
      },
    },
  },
};
</script>
<style lang="scss">
.btn {
  background: linear-gradient(121.03deg, #55b6e2 15.79%, #604fe6 64.07%);
  border-radius: 30px;
  color: #fff;
}
.gladient-bg {
  background: linear-gradient(
    121.03deg,
    #55b6e2 15.79%,
    #604fe6 64.07%
  ) !important;
  color: #fff !important;
}
.appbar {
  .v-text-field input {
    font-size: 48px;
    font-weight: 100;
    text-transform: capitalize;
    height: 48px !important;
    max-height: initial;
  }
  .v-text-field label {
    font-size: 48px;
    line-height: 48px;
    height: 48px !important;
    max-height: initial;
  }
  .v-text-field .v-label--active {
    max-width: 133%;
    transform: translateY(-43px) scale(0.75);
  }
}
.animated {
  transition: all 0.5s;
}
#video {
  width: 100%;
  height: 100%;
  background-color: #333;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  &.main {
    position: absolute;
    top: 0;
    left: 0;
    transition: all .9s;
    &.small {
      left: 15px;
      top: 15px;
      transform: scale(0.3);
      transform-origin: top left;
    }
  }
}
.video_wrapper {
  position: relative;
  width: 590px;
  height: 890px;
  background: #000;
  opacity:1;
  transition: all .3s;
}

.challenge {
  position: absolute;
  top:0;
  left:0;
  z-index: 101;
  width: 100%;
}

.creditBattleReadyCheck {
  position: fixed;
  width: 300px;
  top: 16px;
  z-index: 3000;
}

.creditBattleReadyCheck {
  position: fixed;
  width: 300px;
  top: 16px;
  z-index: 3000;
}
</style>