<template>
  <main>
    <v-container>
      <v-layout column>
        <h1 class = "font-weight-light">Create new stream</h1>
        <v-layout row align-center>
          <v-flex>
            <div style = "position: relative; display: inline-block; width:590px; margin: 0 auto">
              <video
                  id="video"
                  autoplay
                  width="590px"
                  height="890px"
                  style = "object-fit: cover;"
                  ref="video"
                  loop
                ></video>
                <div class = "overlay" />
                <div class = "overlay" />
                <v-layout wrap class = "bottombar">
                    <div class = "fileChoser">
                      <v-icon size="43" color="white">mdi-image</v-icon>
                      <input type="file" ref="input" @change="(event) => fileChange(event)" />
                    </div>
                  <v-spacer/>
                  <v-flex class = "text-center">
                    <div class = "record" @click = "record">
                      <v-icon color="white" size="42">
                        {{ recorder ? 'mdi-stop' : 'mdi-camera' }}
                      </v-icon>
                    </div>
                  </v-flex>
                  <v-spacer/>
                </v-layout>
            </div>
          </v-flex>
            <v-flex>
            <v-text-field v-model="stream.title" label="Challenge title" />
            <v-text-field v-model.number="stream.entryFee" label="Entry Fee" />
            <v-btn class = "btn" @click="create">Create</v-btn>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-container>
  </main>
</template>

<script>
import axios from "axios";
import { URL as Uri } from "../utils/consts";
import { getUser } from "../utils/login";
export default {
  data() {
    return {
      stream: {
        title: "",
        cover: "",
        entryFee: null
      },
      presignedUrl: "",
      streamObj: null,
      recorder: null,
      recorded: false
    };
  },
  created() {
    navigator.mediaDevices.getUserMedia({ video: true })
    .then((stream) => {
      this.$refs.video.srcObject = stream;
      this.streamObj = stream;
    })
    .catch(function (err) {
      console.log("Something went wrong!", err);
    });
  },
  methods: {
    record() {
      if (!this.recorder) {
        this.recorder = new MediaRecorder(this.streamObj, {
          mimeType: 'video/webm'
        });
        this.recorder.start();
      } else {
        this.recorder.ondataavailable = e => {
          this.uploadFile(e.data);
          this.recorder = null
        };
        this.recorder.stop();
      }
    },
    async create() {
      const { data } = await axios.post(
        Uri() + "rooms",
        {
          ...this.stream,
          cover: {
            image: null,
            video: this.stream.cover,
          },
          entryFee: this.stream.entryFee ?? null
        },
        {
          headers: {
            Authorization: "Bearer " + getUser().token,
          },
        }
      );
      console.log(data);
      this.$router.go("/streaming/" + data.id);
    },
    async uploadFile (videoData) {
      const { data } = await axios.post(
        Uri() + "aws/signedUrl",
        {
          files: [
            {
              name: 'recorded.mp4',
              type: "video/mp4",
            },
          ],
        },
        {
          headers: {
            Authorization: "Bearer " + getUser().token,
          },
        }
      );
      var options = {
        headers: {
          "Content-Type": "video/mp4",
        },
      };
      console.log(videoData);
      

      this.stream.cover = data[0].url;

      console.log(data)

      const { data: aws } = await axios.put(data[0].signedUrl, videoData, options);
      console.log(aws)
      this.$refs.video.srcObject = null;
      this.$refs.video.src = data[0].url;
      this.recorded = true
    },
    async fileChange(event) {
      console.log(event);
      const file = event.target.files[0];
      const { data } = await axios.post(
        Uri() + "aws/signedUrl",
        {
          files: [
            {
              name: file.name,
              type: file.type,
            },
          ],
        },
        {
          headers: {
            Authorization: "Bearer " + getUser().token,
          },
        }
      );
      var options = {
        headers: {
          "Content-Type": file.type,
        },
      };

      this.stream.cover = data[0].url;

      const { data: aws } = await axios.put(data[0].signedUrl, file, options);
      console.log(aws);
      this.$refs.video.srcObject = null;
      this.$refs.video.src = data[0].url;
      this.$refs.video.loop = true;
      console.log(data[0].url);
    },
  },
};
</script>

<style lang="scss" scoped>
.bottombar {
  position:absolute;
  width: 100%;
  bottom:0;
  left:0;
  right:0;
  padding-bottom: 30px;
}
.record {
  display: inline-block;
  padding: 20px;
  border-radius: 50%;
  background: linear-gradient(180deg, #D568E1 0%, #E4928A 100%);
  color: #FFF;
}
.overlay {
  position:absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  transform:rotate(180deg);
  opacity: .94;
  background: linear-gradient(180deg, #0C0731 -20.84%, rgba(12, 7, 49, 0) 73.72%);;
}
.overlay:nth-last-of-type(even) {
  position:absolute;
  top:0;
  left:0;
  right: 0;
  bottom: 0;
  opacity: .94;
  background: linear-gradient(0deg, #0C0731 -20.84%, rgba(12, 7, 49, 0) 73.72%);;
}
.fileChoser {
  position:absolute;
  left:15px;
  bottom:38px;
  margin:10px;

  input {
    cursor: pointer;
    position:absolute;
    opacity:0;
    width:100%;
    height: 100%;
    z-index: 10;
    left:0;
  }
}
.btn {
  width:100%;
  background: linear-gradient(121.03deg, #55b6e2 15.79%, #604fe6 64.07%);
  border-radius: 30px;
  color: #fff;
}
</style>